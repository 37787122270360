import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHandHoldingUsd, faPencil, faReceipt } from '@fortawesome/pro-light-svg-icons'

import { useSetState } from '@campaignhub/react-hooks'

import {
  Box, Button, Form, Link, Text,
} from '@campaignhub/suit-theme'

import { parseSpendData } from '@functions/adCampaign'
import systemColours from '@functions/colours'

import useAdCampaign, { useAdCampaignForm } from '@hooks/useAdCampaign'

import { AdCampaignModel } from '@models/types'

import type { ManageAdCampaignModalProps } from '..'

const defaultState = {
  editingSpend: false,
}

type SpendDetailsProps = {
  adCampaign: AdCampaignModel,
  callbacks: ManageAdCampaignModalProps['callbacks'],
}

const SpendDetails = (props: SpendDetailsProps) => {
  const {
    adCampaign,
    adCampaign: {
      adChannelTypeId,
    },
    callbacks: {
      updateAdCampaign,
    },
  } = props

  const [state, setState] = useSetState(defaultState)
  const { editingSpend } = state

  // Editing spend only available for Facebook
  const canEditSpend = adChannelTypeId === 'Facebook'

  const {
    callbacks: {
      updateAdCampaign: updateFn,
    },
  } = useAdCampaign(adCampaign)

  const {
    entityState,
    entityState: {
      spend: {
        budget: { value: entityStateTotalBudget },
      },
    },
    handlers,
  } = useAdCampaignForm(adCampaign)

  const { remaining, spent, totalBudget } = parseSpendData(adCampaign)

  const updateAdCampaignPayload = {
    callbacks: {
      action: updateFn,
    },
    entityParams: entityState,
    toastText: 'Budget Updated',
  }

  return (
    <Box flexDirection="column">
      <Text color="bodyFontLightColor" fontSize="small">Spend</Text>

      {!editingSpend && (
        <Box
          alignItems="center"
          justifyContent="space-between"
          marginTop="medium"
          padding="large"
          variant="white"
        >
          <Box flexDirection="column">
            <Text color="bodyFontLightColor" fontSize="xsmall">Total Budget</Text>
            <Text marginTop="small">${totalBudget}</Text>
          </Box>

          {!canEditSpend && <FontAwesomeIcon color={systemColours.faintGrey} icon={faHandHoldingUsd} />}

          {canEditSpend && (
            <Link
              greyLink
              onClick={() => setState({ editingSpend: true })}
            >
              <FontAwesomeIcon icon={faPencil} />
            </Link>
          )}
        </Box>
      )}

      {editingSpend && (
        <Box
          alignItems="center"
          flexDirection="column"
          justifyContent="space-between"
          marginTop="medium"
          padding="large"
          variant="white"
        >
          <Box flexDirection="column">
            <Form.Field label="Override Total Budget">
              <input
                name="spend.budget.value"
                value={entityStateTotalBudget}
                type="number"
                {...handlers}
              />
            </Form.Field>
          </Box>

          <Box justifyContent="space-between" marginTop="medium">
            <Button
              buttonStyle="secondaryUtility"
              onClick={() => setState({ editingSpend: false })}
              size="medium"
              width="calc(50% - 4px)"
            >
              Cancel
            </Button>

            <Button
              buttonStyle="primaryCreate"
              onClick={() => updateAdCampaign(updateAdCampaignPayload)}
              size="medium"
              width="calc(50% - 4px)"
            >
              Save
            </Button>
          </Box>
        </Box>
      )}

      <Box marginTop="medium" marginRight="small">
        <Box
          alignItems="center"
          justifyContent="space-between"
          marginRight="medium"
          padding="large"
          variant="white"
        >
          <Box flexDirection="column">
            <Text color="bodyFontLightColor" fontSize="xsmall">Spent</Text>
            <Text marginTop="small">${spent}</Text>
          </Box>

          <FontAwesomeIcon color={systemColours.faintGrey} icon={faHandHoldingUsd} />
        </Box>

        <Box
          alignItems="center"
          justifyContent="space-between"
          padding="large"
          variant="white"
        >
          <Box flexDirection="column">
            <Text color="bodyFontLightColor" fontSize="xsmall">Remaining</Text>
            <Text marginTop="small">${remaining}</Text>
          </Box>

          <FontAwesomeIcon color={systemColours.faintGrey} icon={faReceipt} />
        </Box>
      </Box>
    </Box>
  )
}

export default SpendDetails
