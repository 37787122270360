import { DateTime } from 'luxon'

import { calculateDaysBetweenDates } from '@functions/dates'

import { AdCampaignModel } from '@models/types'

const getAdCampaignChannel = (adCampaign: AdCampaignModel) => {
  const { adChannelTypeId } = adCampaign

  if (adChannelTypeId === 'Google'){
    const {
      googleAdCampaign: {
        googleCampaignTypeId,
      },
    } = adCampaign

    if (googleCampaignTypeId === 'Search') return 'Google Search'
    if (googleCampaignTypeId === 'DisplayNetwork') return 'Google Display'
  }

  return 'Facebook'
}

export const calculatePercentComplete = (today: string, adCampaigns: AdCampaignModel[]) => {
  const dates = adCampaigns.reduce((acc, adCampaign) => {
    const { startsAt, endsAt } = adCampaign

    if (startsAt) acc.push(startsAt)
    if (endsAt) acc.push(endsAt)

    return acc
  }, [] as string[])

  if (!dates.length) return 0

  const sortedDates = dates.sort()
  const earliestDate = sortedDates[0]
  const latestDate = sortedDates[sortedDates.length - 1]

  // Convert to luxon's DateTime format so we have access to helper methods
  const formattedEarliestDate = DateTime.fromISO(earliestDate)
  const formattedLatestDate = DateTime.fromISO(latestDate)
  const formattedToday = DateTime.fromISO(today)

  const duration = formattedLatestDate.diff(formattedEarliestDate, 'days').values.days
  const elapsed = formattedToday.diff(formattedEarliestDate, 'days').values.days

  const percentageComplete = (elapsed / duration) > 1 ? 1 : elapsed / duration

  return Math.round(percentageComplete * 100) / 100
}

export const parseSpendData = (adCampaign: AdCampaignModel) => {
  const {
    endsAt,
    spend: {
      budget: {
        period: budgetPeriod,
        value: initBudget,
      },
      spent: {
        value: spent,
      },
    },
    startsAt,
  } = adCampaign

  let totalBudget = initBudget
  if (budgetPeriod === 'Daily'){
    const duration = calculateDaysBetweenDates(startsAt, endsAt)
    totalBudget = initBudget * duration
  }

  return {
    remaining: Math.round((totalBudget - spent) * 100) / 100,
    spent: Math.round(spent * 100) / 100,
    totalBudget: Math.round(totalBudget * 100) / 100,
  }
}

export const generateAdsManagerUrl = (adCampaign: AdCampaignModel) => {
  const {
    adAccountId,
    adChannelTypeId,
    facebookAdCampaign,
    googleAdCampaign,
  } = adCampaign

  const { facebookCampaignId } = facebookAdCampaign || {}
  const { googleCampaignId } = googleAdCampaign || {}

  return adChannelTypeId === 'Google'
    ? `https://ads.google.com/aw/adgroups?campaignId=${googleCampaignId}`
    : `https://adsmanager.facebook.com/adsmanager/manage/adsets?act=${adAccountId}&selected_campaign_ids=${facebookCampaignId}`
}

export default getAdCampaignChannel
