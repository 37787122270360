import { useContext } from 'react'

import { useForm, useLatestEntity } from '@campaignhub/react-hooks'
import type { UseFormOptions } from '@campaignhub/react-hooks'

import { launchModal } from '@campaignhub/javascript-utils'

import useDispatch from '@hooks/useDispatch'
import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import * as adCampaignActions from '@redux/modules/adCampaign'

import PageContext from '@contexts/pageContext'

import defaultFormState, { requiredFields } from '@models/adCampaign'
import type { AdCampaignModel, AdCampaignRequestOptions, AdGroupModel } from '@models/types'

import type { AppDispatch } from '@redux/store'
import type { ModuleState } from '@redux/modules/types'

type DeleteAdCampaignParams = {
  adCampaign: AdCampaignModel,
  dispatch: AppDispatch,
}

const deleteAdCampaign = (params: DeleteAdCampaignParams) => {
  const { adCampaign, dispatch } = params
  const { deleteAdCampaign: deleteFn } = adCampaignActions

  return dispatch(deleteFn(adCampaign))
}

type DuplicateAdCampaignParams = {
  adCampaign: AdCampaignModel,
  dispatch: AppDispatch,
}

const duplicateAdCampaign = (params: DuplicateAdCampaignParams) => {
  const { adCampaign, dispatch } = params
  const { duplicateAdCampaign: duplicateFn } = adCampaignActions

  return dispatch(duplicateFn(adCampaign))
}

type SyncAdCampaignParams = {
  adCampaign: AdCampaignModel,
  dispatch: AppDispatch,
}

const syncAdCampaign = (params: SyncAdCampaignParams) => {
  const { dispatch, adCampaign } = params
  const { syncAdCampaign: syncFn } = adCampaignActions

  return dispatch(syncFn(adCampaign))
}

type UpdateAdCampaignParams = {
  adCampaignParams: AdCampaignModel,
  dispatch: AppDispatch,
  requestOptions?: AdCampaignRequestOptions,
}

const updateAdCampaign = (params: UpdateAdCampaignParams) => {
  const {
    adCampaignParams, dispatch, requestOptions,
  } = params
  const { updateAdCampaign: updateFn } = adCampaignActions

  return dispatch(updateFn(adCampaignParams, requestOptions))
}

type UpdateAdCampaignStatusParams = {
  adCampaign: AdCampaignModel,
  dispatch: AppDispatch,
  status: string,
}

const updateAdCampaignStatus = (params: UpdateAdCampaignStatusParams) => {
  const { adCampaign, dispatch, status } = params
  const { updateAdCampaignStatus: updateStatusFn } = adCampaignActions

  return dispatch(updateStatusFn(adCampaign, status))
}

export const useRelations = (adCampaign: Partial<AdCampaignModel> = {}) => {
  const { campaignId } = adCampaign

  const entities = useSelector(reduxState => reduxState.entities)
  const { campaigns } = entities

  const campaign = campaignId && campaigns[campaignId] ? campaigns[campaignId] : {}

  return {
    campaign,
  }
}

type CustomFormOptions = {
  customRequiredFields?: UseFormOptions['requiredFields'],
}

export function useAdCampaignForm(
  adCampaign: Partial<AdCampaignModel>,
  options: UseFormOptions & CustomFormOptions = {},
) {
  const { customRequiredFields = [], validateOn } = options || {}

  const adCampaignForm = useForm(
    defaultFormState,
    {
      entity: adCampaign,
      requiredFields: [...requiredFields, ...customRequiredFields],
      validateOn,
    },
    [adCampaign.id, adCampaign.cacheKey],
  )

  return {
    ...adCampaignForm,
  }
}

type Options = {
  performHttpRequests?: boolean,
  requestOptions?: AdCampaignRequestOptions,
}

function useAdCampaign(initEntity: Partial<AdCampaignModel> = {}, options: Options = {}) {
  const { performHttpRequests, requestOptions } = options

  const { entity: adCampaign }: { entity: AdCampaignModel } = useLatestEntity(initEntity, 'adCampaigns')
  const { id } = adCampaign

  const dispatch = useDispatch()

  const { callbacks } = useContext(PageContext)

  useReduxAction(
    'adCampaigns',
    'loadAdCampaign',
    {
      entityId: id,
      ...requestOptions,
    },
    [id, performHttpRequests],
    {
      dispatchAction: (action, actionRequestOptions) => action(adCampaign, actionRequestOptions),
      shouldPerformFn: ({ loading }: ModuleState) => !!performHttpRequests && !loading,
    },
  )

  const { loading, updating } = useSelector(reduxState => reduxState.adCampaigns)

  return {
    callbacks: {
      deleteAdCampaign: () => deleteAdCampaign({ dispatch, adCampaign }),
      duplicateAdCampaign: () => duplicateAdCampaign({ dispatch, adCampaign }),
      manageAdCampaign: () => launchModal({
        callbacks,
        modalKey: 'ManageAdCampaignModal',
        payload: { adCampaign },
      }),
      syncAdCampaign: () => syncAdCampaign({ adCampaign, dispatch }),
      updateAdCampaign: (adCampaignParams: AdCampaignModel, entityOptions?: AdCampaignRequestOptions) => (
        updateAdCampaign({
          adCampaignParams, dispatch, requestOptions: entityOptions,
        })
      ),
      updateAdCampaignStatus: (status: string) => updateAdCampaignStatus({ adCampaign, dispatch, status }),
      viewAdGroups: () => launchModal({
        callbacks,
        modalKey: 'AdGroupModal',
        payload: { adCampaign },
      }),
      viewAdSetDetails: (adGroup: AdGroupModel) => launchModal({
        callbacks,
        modalKey: 'AdSetDetailsModal',
        payload: { adCampaign, adGroup },
      }),
      viewLocationTargeting: () => launchModal({
        callbacks,
        modalKey: 'LocationTargetingModal',
        payload: { adCampaign },
      }),
    },
    adCampaign,
    loading,
    updating,
  }
}

export default useAdCampaign
